import { useState, useEffect } from 'react';

interface CountryCheckResult {
  isLoading: boolean;
  isAllowed: boolean;
  error: string | null;
  country: string | null;
  region?: string | null;
}

export const useCountryCheck = (
  blockedCountries: string[] = [],
  allowedUSRegions: string[] = []
): CountryCheckResult => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAllowed, setIsAllowed] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [country, setCountry] = useState<string | null>(null);
  const [region, setRegion] = useState<string | null>(null);

  useEffect(() => {
    const checkCountryAccess = async () => {
      try {
        const response = await fetch(
          'https://apistaging.sendsprint.com/testemail/getuserlocationlocal ',
          {
            headers: {
              Accept: 'application/json',
              'Cache-Control': 'no-cache'
            }
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseText = await response.text();

        // console.log('responseText', responseText);
        // let jsonData;

        // Handle JSONP format
        // if (responseText.startsWith('showIP(')) {
        //   const jsonStr = responseText.replace('showIP(', '').replace(/\);?$/, '');
        //   jsonData = JSON.parse(jsonStr);
        // } else {
        //   // Try direct JSON parse
        //   jsonData = JSON.parse(responseText);
        // }

        const jsonData = JSON.parse(responseText);

        // console.log(jsonData, 'hshs');

        if (!jsonData.countryCode) {
          throw new Error('No country code in response');
        }

        setCountry(jsonData.country);
        setRegion(jsonData.regionName);
        if (blockedCountries.includes(jsonData.countryCode)) {
          setIsAllowed(false);
          return;
        }

        if (jsonData.countryCode === 'US') {
          if (allowedUSRegions.length > 0 && !allowedUSRegions.includes(jsonData.regionName)) {
            setIsAllowed(false);
            return;
          }
        }
        setIsAllowed(true);
      } catch (err) {
        setError('Unable to verify your location. Please try again later.');
        setIsAllowed(true);
      } finally {
        setIsLoading(false);
      }
    };

    checkCountryAccess();
  }, [blockedCountries]);

  return { isLoading, isAllowed, error, country, region };
};
